
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import ComparatoreLeadSystem from './pages/ComparatoreLeadSystem';
import Header from './components/Header';
import Risultati from './pages/Risultati';
import Footer from './components/Footer';
import Home from './pages/Home';
import ComparatoreTech from './pages/ComparatoreTech';
import RisultatiTech from './pages/RisultatiTech';
import SchedaCorso from './pages/SchedaCorso';

function App() {
  return (
    <Router>
      <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/universita" element={<ComparatoreLeadSystem />} />
          <Route path="/universita/risultati" element={<Risultati />} />
          <Route path="/digital-tech" element={<ComparatoreTech />} />
          <Route path="/digital-tech/risultati" element={<RisultatiTech />} />
          <Route path="/universita/risultati/:nomeCorso" element={<SchedaCorso />} />
        </Routes>
      <Footer />  
    </Router>
  );
}

export default App;
