export const schedaCorsi = {
    "Comunicazione e Multimedialità": {
      "Area": "Scienze della Comunicazione",
      "H1": "Corso di Laurea in Comunicazione e Multimedialità, Università Digitale Mercatorum",
      "tag 1": "Laurea triennale",
      "tag 2": "Durata: 3 anni",
      "tag 3": "180 cfu",
      "tag 4": "Anno accademico 2023/2024",
      "tag 5": "L-20",
      "Descrizione": "La laurea in Comunicazione e Multimedialità (L-20) dell’Università telematica Mercatorum mira a formare professionisti capaci di operare nei vari settori della comunicazione, sia in ambito pubblico che privato. Questo corso combina l'insegnamento delle tecniche di Comunicazione Visiva e Multimediale proprie del marketing digitale , a un approccio umanistico con materie che attengono all'area letteraria e sociologica, offrendo agli studenti una solida preparazione teorica e pratica nel mondo della comunicazione digitale e non.",
      "Piano di studi 1": "Semiotica Generale M-FIL/05\nStoria Contemporanea M-STO/04\nMetodologia della ricerca nella società digitale SPS/07\nSociologia della comunicazione SPS/08\nSemiotica dei Media L-ART/06\nComunicazione interna nell'organizzazione aziendale SECS-P/10\nAbilità informatiche e telematiche INF/01",
      "Piano di studi 2": "Processi economici e produttivi delle Media Company SPS/09\nSearch Engine Optimization ING-INF/05\nTecniche della rappresentazione digitale ICAR/17\nArti visuali e nuove tecnologie rappresentative L-ART/04\nTecnologie digitali e processi cognitivi M-PED/04\nNarratologia e storytelling L-FIL-LET/14\nCommunity management M-PSI/07",
      "Piano di studi 3": "Audiovisivi digitali L-ART/06\nElementi di Marketing e Digital Advertising Strategy SECS-P/08\nDigital and Social Media Management SPS/08\nDiritto pubblico, dell'informazione e della comunicazione IUS/09\nInsegnamento a scelta\nUlteriori conoscenze linguistiche\nPer la conoscenza di almeno una lingua straniera\nProva Finale",
      "operatore 1": "Mercatorum",
      "operatore 2": "Comparacorsi",
      "operatore 3": "Altri operatori",
      "prezzo 1": "3.400€",
      "prezzo 2": "1.700€",
      "prezzo 3": "4.000€",
      "Domanda 1": "Qual è l'obiettivo del corso?",
      "Risposta 1": "Il corso mira a fornire una solida preparazione culturale e padronanza dei metodi di analisi delle informazioni e dei dati nell'ambito della comunicazione, con particolare attenzione ai nuovi media e alla digitalizzazione dei processi comunicativi.",
      "Domanda 2": "Quali sono le opportunità lavorative dopo il completamento del corso?",
      "Risposta 2": "I laureati possono lavorare in diversi settori della comunicazione, sia in ambito pubblico che privato, includendo ruoli come responsabili della comunicazione, digital media strategists, e posizioni in aziende, editorie, agenzie creative, e media.",
      "Domanda 3": "Quali sono i requisiti di ammissione?",
      "Risposta 3": "È richiesto un diploma di scuola media superiore o un titolo equivalente, un'adeguata preparazione di base in lingua italiana, una buona conoscenza della cultura generale e conoscenze di base di una seconda lingua dell'Unione Europea.",
      "Domanda 4": "Qual è la modalità di erogazione delle lezioni?",
      "Risposta 4": "Le lezioni del corso in Comunicazione e Multimedialità sono erogate online, permettendo agli studenti di accedere al materiale didattico e partecipare alle attività formative in modo flessibile, adattandosi alle loro esigenze di tempo e luogo. Questo include lezioni in video, materiali di studio, e la possibilità di interagire con docenti e compagni di corso attraverso piattaforme digitali.",
      "Domanda 5": "Qual è la durata e la struttura del corso?",
      "Risposta 5":"Il corso ha una durata di 3 anni e include insegnamenti teorici e pratici in ambiti come la semiotica, la sociologia della comunicazione, le tecnologie digitali, e il digital marketing.",
      "Sbocchi": [
        "Specialista in Comunicazione Digitale",
        "Produttore Multimediale",
        "Social Media Manager",
        "SEO Specialist",
        "Giornalista Digitale",
        "Copywriter",
        "Stratega di Contenuto",
        "Analista di Dati e Metriche Digitali",
        "Responsabile PR Digitali",
      ],
    },
    "Scienze della Comunicazione (Digital Entertainment and Gaming)": {
      "Area": "Scienze della Comunicazione",
      "H1": "Corso di Laurea in Digital Entertainment and Gaming, Università Digitale eCampus",
      "tag 1": "Laurea triennale",
      "tag 2": "Durata: 3 anni",
      "tag 3": "180 cfu",
      "tag 4": "Anno accademico 2023/2024",
      "tag 5": "L-20",
      "Descrizione": "L'università digitale eCampus propone un affascinante corso di laurea triennale in Digital Entertainment and Gaming. Questo corso si concentra sulla comprensione, l'analisi e l'utilizzo degli strumenti di comunicazione moderna, enfatizzando aree come la pubblicità, i multimedia , il marketing, la comunicazione d'impresa e l'e-commerce. A differenza di altri programmi legati al mondo digitale, la sua unicità risiede nell'intenso focus sul digital entertainment, il gaming e l'industria dei videogiochi, integrando competenze come il game development, il game design e la gamification.",
      "Piano di studi 1": "Semiotica e filosofia dei linguaggi, Informatica, Organizzazione aziendale, Sociologia dei processi economici, Tecnica, storia e linguaggio dei mezzi audiovisivi, Estetica della comunicazione",
      "Piano di studi 2": "Diritto dell'informazione e della comunicazione, Metodologia della ricerca sociale, Lingua inglese, Sociologia della comunicazione, Psicologia della moda, Sociologia della moda, Progettazione, processi e comportamenti organizzativi",
      "Piano di studi 3": "Etica della comunicazione, Linguaggi dei nuovi media, Lingua spagnola, Laboratori di scrittura istituzionale, pubblicitaria e lettura dell'immagine, Materie a scelta dello studente, Tirocini formativi e di orientamento",
      "operatore 1": "eCampus",
      "operatore 2": "Comparacorsi",
      "operatore 3": "Altri operatori",
      "prezzo 1": "5.900€",
      "prezzo 2": "2.600€",
      "prezzo 3": "6.000€",
      "Domanda 1": "Qual è l'obiettivo principale del corso di laurea in Digital Entertainment and Gaming all'università eCampus?",
      "Risposta 1": "Il corso mira a formare professionisti nel campo del digital marketing e dell'intrattenimento online, con un focus particolare sul digital entertainment, il gaming e l'industria dei videogiochi",
      "Domanda 2": "Quali materie sono incluse nel piano di studi di questo corso di laurea?",
      "Risposta 2": "Il piano di studi include materie come Semiotica, Informatica, Organizzazione aziendale, Diritto dell'informazione, Psicologia della moda, Linguaggi dei nuovi media, e molte altre, oltre a laboratori e materie a scelta dello studente.",
      "Domanda 3": "Che tipo di competenze pratiche svilupperanno gli studenti in questo corso?",
      "Risposta 3": "Gli studenti acquisiranno competenze in aree come SEO, branding digitale, email marketing, content marketing, web analytics, mobile marketing, oltre a strategie di marketing online e analisi dati",
      "Domanda 4": "Quali sono i principali sbocchi lavorativi dopo aver completato questo corso?",
      "Risposta 4": "I laureati possono lavorare nel settore del digital entertainment and gaming, e-commerce, social media, video marketing, e mobile marketing, con ruoli che variano dal game design al branding digitale e alla gestione dei social media.",
      "Domanda 5": "Quali sono i requisiti di ammissione per il corso di laurea in Digital Entertainment and Gaming all'università eCampus?",
      "Risposta 5":"Gli studenti devono avere un diploma di scuola secondaria superiore o un titolo equivalente ottenuto all'estero. È inoltre essenziale avere una buona conoscenza della lingua italiana e dell'inglese. La valutazione per l'ammissione avviene tramite un test d'ingresso.",
      "Sbocchi": [
        "Analista dati per videogiochi",
        "Esperto di branding digitale per giochi e piattaforme",
        "Specialista in SEO localizzato",
        "Specialista in email marketing",
        "Specialista in content marketing",
        "Specialista in conversioni e ROI",
        "Social media manager",
        "Influencer marketing",
        "Sviluppatore di campagne di storytelling digitale",
        "Esperto di mobile marketing"
      ],
    },
    "Scienze della Comunicazione (Influencer)":  {
      "Area": "Scienze della Comunicazione",
      "H1": "Corso di Laurea in Influencer, Università Digitale eCampus",
      "tag 1": "Laurea triennale",
      "tag 2": "Durata: 3 anni",
      "tag 3": "180 cfu",
      "tag 4": "Anno accademico 2023/2024",
      "tag 5": "L-20",
      "Descrizione": "Nell'era del marketing digitale, dove i social media regnano sovrani, nasce un innovativo corso universitario dedicato all'influencer presso l'ateneo eCampus. Questa laurea triennale in Scienze della Comunicazione si focalizza sull'importanza crescente del ruolo dell'influencer nella comunicazione online. L'influencer è un leader d'opinione che può diventare ambasciatore di se stesso e dei brand con cui collabora. Gli influencer, con il loro forte personal branding, hanno il potere di influenzare le decisioni d'acquisto e le opinioni del loro pubblico attraverso strategie di influencer ben studiate.",
      "Piano di studi 1": "Semiotica e filosofia dei linguaggi\nInformatica\nEstetica della comunicazione\nSociologia dei processi economici\nTecnica, storia e linguaggio dei mezzi audiovisivi\nOrganizzazione aziendale",
      "Piano di studi 2": "Diritto dell'informazione e della comunicazione\nMetodologia della ricerca sociale\nLingua inglese\nSociologia della comunicazione e dell'informazione\nPsicologia della moda\nSociologia della moda\nProgettazione, processi e comportamenti organizzativi\nA scelta dello studente",
      "Piano di studi 3": "Linguaggi dei nuovi media\nEtica della comunicazione\nSocial media marketing\nLingua spagnola\nLaboratorio di scrittura istituzionale e pubblicitaria\nLaboratorio di scrittura\nLaboratorio di lettura dell'immagine\nA scelta dello studente\nTirocini formativi e di orientamento\nProva finale",
      "operatore 1": "eCampus",
      "operatore 2": "Comparacorsi",
      "operatore 3": "Altri operatori",
      "prezzo 1": "5.900€",
      "prezzo 2": "2.600€",
      "prezzo 3": "6.000€",
      "Domanda 1": "Qual è l'obiettivo del corso di laurea in Influencer presso l'Università eCampus?",
      "Risposta 1": "Il corso si concentra sulla formazione di influencer competenti nel marketing digitale, con un'enfasi sul personal branding, la gestione dei social media e la creazione di contenuti efficaci.",
      "Domanda 2": "Quali materie sono incluse nel piano di studi?",
      "Risposta 2": "Il piano di studi comprende Semiotica, Informatica, Estetica della comunicazione, Diritto dell'informazione, Psicologia e Sociologia della moda, Social media marketing, tra gli altri.",
      "Domanda 3": "Quali competenze pratiche acquisiranno gli studenti?",
      "Risposta 3": "Gli studenti acquisiranno competenze in gestione dei social media, creazione di contenuti, pubblicità digitale, analisi delle tendenze, e strategie di influencer marketing.",
      "Domanda 4": "Quali opportunità lavorative sono disponibili per i laureati in questo corso?",
      "Risposta 4": "I laureati possono lavorare come tecnici del marketing, specialisti della pubblicità, professionisti nell'editoria e nell'entertainment, esperti di social media marketing e collaborazioni influencer.",
      "Domanda 5": "Quali sono i requisiti di ammissione?",
      "Risposta 5": "È richiesto un diploma di scuola secondaria superiore o equivalente, una buona conoscenza dell'italiano e dell'inglese.",
      "Sbocchi": [
        "Tecnici del Marketing", 
        "Specialisti della Pubblicità",
        "Professionisti dell'Editoria",
        "Esperti nell'Entertainment",
        "Esperti di Social Media Marketing",
        "Gestori di Collaborazioni Influencer"
      ],
    },
}