import React from 'react'
import logo from '../imgs/logo-miglior-corso-viola.png';
import './header.css';

const Header = () => {
  return (
    <div className='header'>
        <img src={logo} alt='logo comparacorsi' />
    </div>
  )
}

export default Header